@font-face {
    font-family: "Avenir";
    src: url("fonts/Lato-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Avenir";
    src: url("fonts/Lato-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Avenir";
    src: url("fonts/Lato-Black.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}